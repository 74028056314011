<template>
	<b-overlay :show="loading" rounded="sm">
		<div>
			<b-card no-body class="mb-4">
				<template v-if="!readOnly">
					<Header />
					<hr class="mb-4" />
				</template>

				<!-- Search Control -->
				<div class="d-flex justify-content-between mb-2">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search"
								placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex align-items-center btn-grp"
						v-if="currentRoute.includes('/loans/applications') && !readOnly">
						<b-button variant="primary" v-b-modal.modal-upload-files class="d-flex align-items-center mr-0">
							<feather type="upload" class="feather-sm"></feather>
							<span class="ml-1">Upload File</span>
						</b-button>
					</div>
				</div>
				<!-- End Section -->

				<div class="py-4">
					<h4>Customer Documents</h4>
					<!-- Data Table -->
					<b-table style="min-height: 250px;" show-empty responsive small class="mb-0" head-variant="light"
						:items="documents" :filter="filter" :fields="fields" hover>
						<template #cell(id)="data"> #{{ data.item.id }} </template>
						<template #cell(createDate)="data"> {{ data.item.createDate.split("T")[0] }} </template>
						<template #cell(isActive)="data">
							<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
							<b-badge variant="danger" v-else>Inactive</b-badge>
						</template>

						<template #cell(view)="data">
							<div class="d-flex">
								<b-button variant="outline-secondary" size="sm" pill
									class="d-flex align-items-center mr-2" @click="goToView(data.item.sourcePath)">
									<feather type="search" class="feather-sm"></feather>
									<span class="ml-1">View</span>
								</b-button>
								<b-button variant="outline-danger" size="sm" pill class="d-flex align-items-center mr-0"
									@click="disableDocument(data.item, false)"
									v-if="data.item.isActive && currentRoute.includes('/loans/applications')">
									<feather type="x" class="feather-sm"></feather>
									<span class="ml-1">Disabled</span>
								</b-button>
								<b-button v-if="!data.item.isActive && currentRoute.includes('/loans/applications')"
									variant="outline-primary" size="sm" pill class="d-flex align-items-center mr-0"
									@click="disableDocument(data.item, true)">
									<feather type="check" class="feather-sm"></feather>
									<span class="ml-1">Enabled</span>
								</b-button>
							</div>
						</template>
					</b-table>
					<!-- End Section -->
					<!-- Pagination -->
					<div class="p-3 d-flex align-items-center">
						<b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
							label-cols-lg="9" label-size="sm" label-class="fw-medium" class="mb-0">
							<b-form-select @input="getData" id="per-page-select" v-model="perPage"
								:options="pageOptions" size="sm"></b-form-select>
						</b-form-group>
						<div class="ml-auto">
							<b-pagination @input="getData" v-model="currentPage" :total-rows="totalDcuments"
								:per-page="perPage" align="fill" class="my-0"></b-pagination>
						</div>
					</div>
					<!-- End Section -->
				</div>


			</b-card>

			<b-modal id="modal-upload-files" title="Upload Files" hide-footer centered @close="clearData">
				<label class="fw-medium mb-1" for="document-types">Document Type</label>
				<b-form-select id="document-types" :options="documentTypes" text-field="description" value-field="id"
					size="sm" class="mb-3" v-model="documentType" required></b-form-select>
				<div class="d-flex">
					<b-form-file id="file" v-model="file" placeholder="Choose a file..."
						drop-placeholder="Drop file here..." accept=".docx,.png,.jpg,.pdf"
						@input="addFile"></b-form-file>
					<!-- <b-button variant="outline-primary" class="d-flex align-items-center ml-2" @click="addFile">
                        <feather type="plus" class="feather-sm"></feather>
                    </b-button> -->
				</div>
				<h5 class="mb-2 mt-4" v-if="files.length > 0">Files</h5>
				<ul class="my-2 p-0">
					<li v-for="(item, idx) in files" :key="idx"
						class="d-flex justify-content-between align-items-center p-2 border-bottom">
						<span>{{ item.name }}</span>
						<b-button variant="outline-danger" size="sm" class="d-flex align-items-center ml-2"
							@click="files.splice(files.indexOf(item), 1)">
							<feather type="trash" class="feather-sm"></feather>
						</b-button>
					</li>
				</ul>

				<div class="d-flex justify-content-end mt-4 btn-grp">
					<b-button variant="secondary" @click="$bvModal.hide('modal-upload-files')"
						class="mr-2">Cancel</b-button>
					<b-button variant="primary" class="d-flex align-items-center mr-0" @click="uploadFiles"
						:disabled="files.length == 0 || documentType == null">
						<feather type="upload" class="feather-sm"></feather>
						<span class="ml-1">Upload</span>
					</b-button>
				</div>
			</b-modal>
		</div>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/customers/Header.vue";
export default {
	name: "Customer",
	components: {
		Header,
	},
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		page: {
			title: "SeachTable",
		},
		filter: null,
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "documentTypeName",
				label: "Document Type",
				sortable: true,
			},
			{
				key: "createDate",
				label: "DocumentDate",
				sortable: true,
			},
			{
				key: "name",
				label: "File Name",
				sortable: true,
			},
			{
				key: "isActive",
				label: "Status",
				sortable: true,
			},
			{
				key: "view",
				label: "View",
				sortable: true,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		file: null,
		documentType: null,
		loading: false,
		files: [],
		currentRoute: window.location.pathname,
	}),
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			documents: "documents/getDocuments",
			totalDcuments: "documents/getTotalDocuments",
			documentTypes: "documents/getDocumentTypes",
		}),
	},
	methods: {
		clearData() {
			this.file = null;
			this.documentType = null;
			this.files = [];
		},
		async getData() {
			this.loading = true;
			await this.$store.dispatch("documents/get_documents", { skip: this.currentPage, take: this.perPage, customerId: this.$route.params.customer });
			this.loading = false;
		},
		async getDocumentTypes() {
			await this.$store.dispatch("documents/get_documentTypes");
		},
		addFile() {
			if (!this.file) return;
			this.files.push(this.file);
			this.file = null;
		},
		async uploadFiles() {
			const formData = new FormData();
			this.files.forEach((file) => {
				formData.append("files", file);
			});

			let data = {
				customerId: this.customer.additionalDetails.customerId,
				loanApplicationId: this.$route.params.customer,
				documentTypeId: this.documentType,
				formData,
			};

			await this.$store.dispatch("documents/add_documents", data);
			await this.getData();
			this.$bvModal.hide("modal-upload-files");
		},
		goToView(path) {
			window.open(`${path}.jpg`, "_blank");
		},
		async disableDocument(document, status) {
			let data = {
				id: document.id,
				name: document.name,
				sourcePath: document.sourcePath,
				isActive: status,
			};
			await this.$store.dispatch("documents/update_documentStatus", data);
			await this.getData();
		},
	},
	mounted() {
		if (!this.new) {
			this.getData();
		}
	},
	created() {
		if (!this.new) {
			this.getData();
		}
		this.getDocumentTypes();
	},
};
</script>
