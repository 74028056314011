<template>
	<b-card no-body>
		<template v-if="!readOnly">
			<Header />
			<hr class="m-0" />
		</template>
		<b-form @submit.prevent="saveData">
			<b-card-body class="p-0 pt-4">
				<div class="p-4">
					<b-row class="align-items-stretch py-2" v-if="loan">
						<b-col sm="4">
							<label class="fw-medium mb-1">Amount</label>
							<b-form-input type="text" :value="loan.loanAmount" readOnly></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1">Installments</label>
							<b-form-input type="text" readOnly :value="loan.periodInMonths"></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1">Monthly Payment</label>
							<b-form-input type="text" readOnly :value="loan.monthlyPayment"></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1">Product Type</label>
							<b-form-input type="text" readOnly :value="loan.loanDescription"></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1">Expected administration fee</label>
							<b-form-input type="text" readOnly :value="expectedFee"></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1">Total expected amount</label>
							<b-form-input type="text" readOnly :value="loan.loanAmount + expectedFee"></b-form-input>
						</b-col>
					</b-row>
				</div>

				<div class="p-4 bg-light">
					<b-row class="align-items-stretch">
						<b-col sm="6" class="">
							<label class="fw-medium mb-1" for="cus4">Reason Type</label>
							<b-form-radio-group v-model="data.reasonTypeId" :options="customersTypes"
								class="mb-3 d-flex" value-field="id" :disabled="readOnly" text-field="name"
								required></b-form-radio-group>
						</b-col>
						<b-col sm="6" class="">
							<label class="fw-medium mb-1" for="fname4">Title </label>
							<b-form-select size="sm" v-model="data.titleId" :disabled="readOnly" :options="optionsTitle"
								required></b-form-select>
						</b-col>
					</b-row>
				</div>
				<div class="p-4">
					<b-row class="align-items-stretch py-2">
						<b-col sm="4">
							<label class="fw-medium mb-1" for="title4">Last Name* </label>
							<b-form-input id="title4" type="text" :readOnly="readOnly" placeholder="Last name here"
								v-model="data.lastName" required></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1" for="comp4">First Name* </label>
							<b-form-input type="text" :readOnly="readOnly" placeholder="First name here"
								v-model="data.firstName" required></b-form-input>
						</b-col>
						<b-col sm="4">
							<label class="fw-medium mb-1" for="comp4">Middle Name </label>
							<b-form-input type="text" :readOnly="readOnly" placeholder="Name here"
								v-model="data.middleName"></b-form-input>
						</b-col>

					</b-row>
				</div>
				<div class="p-4 bg-light">
					<b-row class="align-items-stretch">
						<b-col sm="6">
							<label class="fw-medium mb-1" for="comp4">Address 1* </label>
							<b-form-input type="text" :readOnly="readOnly" placeholder="Address here"
								v-model="data.address1" required></b-form-input>
						</b-col>
						<b-col sm="6">
							<label class="fw-medium mb-1" for="fname4">District </label>
							<b-form-select v-model="data.cityId" :disabled="readOnly" :options="cities"
								text-field="name" value-field="id" required></b-form-select>
						</b-col>
					</b-row>
				</div>
				<div class="p-4">
					<b-row class="align-items-stretch">
						<b-col sm="6">
							<label class="fw-medium mb-1" for="fname4">Phone 1</label>
							<b-form-input type="tel" :readOnly="readOnly" placeholder="Phone number here"
								v-model="data.phone1" required></b-form-input>
						</b-col>
						<b-col snm="6">
							<label class="fw-medium mb-1" for="fname4">Phone 2</label>
							<b-form-input type="tel" :readOnly="readOnly" placeholder="Phone number here"
								v-model="data.phone2"></b-form-input>
						</b-col>
					</b-row>
				</div>

				<div class="p-4">
					<b-row class="align-items-stretch">
						<b-col sm="6" class="mb-4">
							<label class="fw-medium mb-1" for="fname4">Email 1</label>
							<div class="row">
								<div class="col-12 col-sm-8 mb-3 mb-sm-0">
									<b-form-input type="email" :readOnly="readOnly" placeholder="Email here"
										v-model="data.email1" required></b-form-input>
								</div>
								<div class="col-12 col-sm-4 d-flex align-items-center">
									<template v-if="data.email1IsVerified">
										<b-avatar variant="success" class="text-white" size="sm">
											<feather type="check" class="feather-sm"></feather>
										</b-avatar>
										<span class="fw-medium ml-2">Verified</span>
									</template>
									<div class="d-flex align-items-center" v-else>
										<b-button pill variant="primary" v-if="!readOnly"
											@click="sendVerification(data, 1)">Verify Email</b-button>
									</div>
								</div>
							</div>
						</b-col>
						<b-col snm="6">
							<label class="fw-medium mb-1" for="fname4">Email 2</label>
							<div class="row">
								<div class="col-12 col-sm-8 mb-3 mb-sm-0">
									<b-form-input type="email" placeholder="Email here" :readOnly="readOnly"
										v-model="data.email2"></b-form-input>
								</div>
								<div class="col-12 col-sm-4 d-flex align-items-center">
									<template v-if="data.email2IsVerified">
										<b-avatar variant="success" class="text-white" size="sm">
											<feather type="check" class="feather-sm"></feather>
										</b-avatar>
										<span class="fw-medium ml-2">Verified</span>
									</template>
									<div class="d-flex align-items-center" v-else>
										<b-button pill variant="primary" v-if="!readOnly"
											@click="sendVerification(data, 2)">Verify Email</b-button>
									</div>
									<!-- <b-avatar variant="danger" class="text-white" size="sm">
											<feather type="x" class="feather-sm"></feather>
										</b-avatar>
										<span class="fw-medium ml-2">Not Verified</span> -->
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</b-card-body>
			<div class="p-35 border-top" v-if="!readOnly && hasPermission">
				<div class="btn-grp text-right">
					<b-button pill :disabled="loadingSave" variant="primary" class="px-4" type="submit">
						<b-spinner small v-if="loadingSave"></b-spinner>
						<span v-else>Save</span>
					</b-button>
				</div>
			</div>
		</b-form>
	</b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/customers/Header.vue";
export default {
	name: "Customer",
	components: {
		Header,
	},
	props: {
		readOnly: Boolean,
	},
	data: () => ({
		optionsCountry: [
			{ value: null, text: "Please select an option" },
			{ value: "Aruba", text: "Aruba" },
			{ value: "US", text: "Estados Unidos" },
		],
		optionsTitle: [
			{ value: null, text: "Please select an option" },
			{ text: "Miss", value: 1 },
			{ text: "Ms", value: 2 },
			{ text: "Mr", value: 3 },
			{ text: "Mrs", value: 4 },
		],

		loadingCustomerTypes: true,
		loadingVerification: true,
		data: {
			address1: null,
			address2: null,
			address3: null,
			cityId: null,
			email1: null,
			email1IsVerified: null,
			email2: null,
			email2IsVerified: null,
			firstName: null,
			lastName: null,
			middleName: null,
			migrationOldId: null,
			phone1: null,
			phone2: null,
			reasonTypeId: null,
			titleId: null,
		},
		loan: null,
		loadingSave: false,
	}),
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			customersTypes: "customerstypes/getcustomersTypes",
			countries: "countries/getCountries",
			cities: "cities/getCities",
			authorizations: "auth/getAuthorizations",
		}),
		expectedFee() {
			if (!this.loan) return 0
			return (2.5 * this.loan.loanAmount) / 100
		},
		hasPermission() {
			return this.authorizations.includes('/LoanApplications-Add')
		}
	},
	methods: {
		setData() {
			this.data.id = this.customer.id;
			this.data.address1 = this.customer.address1;
			this.data.address2 = this.customer.address2;
			this.data.address3 = this.customer.address3;
			this.data.cityId = this.customer.cityId;
			this.data.email1 = this.customer.email1;
			this.data.email1IsVerified = this.customer.email1IsVerified;
			this.data.email2 = this.customer.email2;
			this.data.email2IsVerified = this.customer.email2IsVerified;
			this.data.firstName = this.customer.firstName;
			this.data.lastName = this.customer.lastName;
			this.data.middleName = this.customer.middleName;
			this.data.migrationOldId = this.customer.migrationOldId;
			this.data.phone1 = this.customer.phone1;
			this.data.phone2 = this.customer.phone2;
			this.data.reasonTypeId = this.customer.reasonTypeId;
			this.data.titleId = this.customer.titleId;
		},
		clearData() {
			this.data = {
				address1: null,
				address2: null,
				address3: null,
				cityId: null,
				email1: null,
				email1IsVerified: null,
				email2: null,
				email2IsVerified: null,
				firstName: null,
				lastName: null,
				middleName: null,
				migrationOldId: null,
				phone1: null,
				phone2: null,
				reasonTypeId: null,
				titleId: null,
			};
		},
		async getCustomerTypes() {
			this.loadingCustomerTypes = true;
			await this.$store.dispatch("customerstypes/get_allCustomersTypes", { skip: 1, take: 9999 });
			this.loadingCustomerTypes = false;
		},
		async getData() {
			await this.$store.dispatch("countries/get_allCountries", { skip: 1, take: 9999 });
			await this.$store.dispatch("cities/get_allCities", { skip: 1, take: 9999 });

			if (this.$route.path.includes('/loans/applications')) {
				const response = await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
				this.loan = response?.dataResult
			}
		},
		async saveData() {
			this.loadingSave = true;
			await this.$store.dispatch("customers/update_customer", this.data);
			this.loadingSave = false;
		},
		async sendVerification(data, number) {
			this.loadingVerification = true;
			let payload = {
				id: data.id,
				firstName: data.firstName,
				lastName: data.lastName,
				emailNumber: number,
			};
			if (number == 1) {
				if (data.email1 == "" || data.email1 == null) return;
				payload.email = data.email1;
			}
			if (number == 2) {
				if (data.email2 == "" || data.email2 == null) return;
				payload.email = data.email2;
			}
			await this.$store.dispatch("customers/send_emailVerification", payload);
			this.loadingVerification = false;
		},
	},
	mounted() {
		this.setData();
		this.getCustomerTypes();
		this.getData();
	},
	watch: {
		customer: function () {
			this.setData();
		},
	},
};
</script>
