<template>
    <div class="select-with-search">
        <div class="select-box" @click="toggleDropdown">
            <div class="selected-option">{{ selectedOptionText }}</div>
            <b-button v-if="selectedOption" size="sm" pill variant="light" @click="clear">Clear</b-button>
        </div>
        <ul v-if="dropdownOpen" class="options-list">
            <li>
                <div class="search-wrapper">
                    <input v-model="searchQuery" type="text" placeholder="Buscar..." @input="onSearchInput"
                        ref="searchInput" />

                </div>
            </li>
            <li v-if="filteredOptions.length === 0">There are no records to show</li>
            <li v-for="option in filteredOptions" :key="option.value" @click="selectOption(option)">
                {{ option.text }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            searchQuery: '',
            selectedOption: null,
            filteredOptions: [],
            dropdownOpen: false
        };
    },
    computed: {
        selectedOptionText() {
            const selected = this.items.find(option => option.value === this.selectedOption);
            return selected ? ` ${selected.value} - ${selected.text}` : 'Selecciona una opción';
        }
    },
    watch: {
        items(newItems) {
            this.filteredOptions = newItems;
        }
    },
    created() {
        this.filteredOptions = this.items;
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
            this.$nextTick(() => {
                if (this.dropdownOpen) {
                    this.$refs.searchInput.focus();
                }
            });
        },
        onSearchInput() {
            this.$emit('search-input', this.searchQuery);
            this.filteredOptions = this.items.filter(option =>
                option.text.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        selectOption(option) {
            this.selectedOption = option.value;
            this.searchQuery = '';
            this.filteredOptions = this.items;
            this.dropdownOpen = false;
            this.$emit('option-selected', option);
        },
        clear(event) {
            event.stopPropagation(); // Prevents the dropdown from toggling
            this.searchQuery = ''
            this.selectedOption = null
        }
    }
};
</script>

<style scoped>
.select-with-search {
    position: relative;
    width: 100%;
}

.select-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selected-option {
    display: inline-block;
    width: calc(100% - 24px);
}

input {
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
}

.options-list {
    position: absolute;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    z-index: 10;
    padding: 0;
    margin: 0;
    list-style: none;
}

.options-list li {
    padding: 8px;
    cursor: pointer;
}

.options-list li:hover {
    background-color: #f0f0f0;
}

.search-wrapper {
    position: relative;
    width: 100%;
}

.clear-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #888;
}
</style>
