<template>
	<div class=" p-35">
		<div class="d-flex justify-content-start align-items-center mr-5 mb-2 px-2">
			<b-avatar size="sm" variant="primary" class="mr-2" rounded></b-avatar>
			<p class="mb-0 mr-2">{{ customer.firstName }} {{ customer.lastName }}</p>
			<b-badge :variant="customer.encodedKey == null || customer.encodedKey == '' ? 'danger' : 'success'">
				Mambu
			</b-badge>
		</div>

		<div class="d-flex justify-content-start">
			<Stepper v-if="currentRoute.includes('/loans/applications')" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Stepper from "@/components/Stepper.vue";
export default {
	components: {
		Stepper,
	},
	data() {
		return {
			currentRoute: window.location.pathname,
		};
	},
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
		}),
	},
};
</script>
